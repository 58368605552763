import React, { useEffect } from 'react'
import "./segmentLivestock.css"
import Aos from "aos";
import "aos/dist/aos.css";
import IconChevronRight from "../../images/vector-6.svg" 
import PlaceholderImage from "../../images/liveStockSegment.png"

export default function SegmentLivestock() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="LivestockSegment-layout-192">
            <div className="LivestockSegment-container2">
              <img
                data-aos="fade-right" data-aos-duration="500"
                className="LivestockSegment-placeholder-image-icon"
                alt=""
                src={PlaceholderImage}
              />
              <div className="LivestockSegment-content3">
                <div className="LivestockSegment-section-title" data-aos="fade-left" data-aos-duration="500">
                  <div className="LivestockSegment-content4">
                    <div className="LivestockSegment-heading">Livestock Solutions</div>
                    <div className="LivestockSegment-text1">
                      CIC’s LIVESTOCLK SOLUTIONS cluster is one of the few fully integrated poultry
                      solutions providers in Sri Lanka. Our facilities include, a fully automated feed
                      mill, breeder farms, state-of-the-art hatchery, broiler farms and a modern chicken
                      processing plant that serve as a vital link in the Country’s poultry supply chain.
                    </div>
                  </div>
                </div>
                <div className="LivestockSegment-actions2" data-aos="fade-left" data-aos-duration="500">
                  <a href='#' className="LivestockSegment-button3">
                    <div className="LivestockSegment-button-child" />
                    <div className="LivestockSegment-read-more">Read More</div>
                    <img className="LivestockSegment-button-item" alt="" src={IconChevronRight} />
                  </a>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
